import Big from 'big.js';

export type AppEnv = {
  isDevnet: boolean;
  isTestnet: boolean;
  isMainnet: boolean;
};

export type Balance = {
  amount: string;
  amountBig: Big;
  amountNum: number;
  valueUSD: string;
  valueUSDNum: number;
  lamports: Big;
};

export type BaseAsset = Pick<
  Asset,
  'name' | 'symbol' | 'id' | 'coingeckoId' | 'decimals' | 'logoURI'
>;

export type Asset = {
  name: string;
  symbol: string;
  logoURI: string;
  price: Big;
  priceUSD: string;
  priceUSDNum: number;
  coingeckoId?: string;
  id: string;
  decimals: number;
  toUSDValue: (lamports: Big) => number;
  toUSDValueStr: (
    lamports: Big,
    options?: {
      hideComma?: boolean;
      hideSymbol?: boolean;
      decimals?: number;
    } & Intl.NumberFormatOptions,
  ) => string;
  amountToUSD: (amount: number) => number;
  amountToUSDStr: (
    amount: number,
    options?: {
      hideComma?: boolean;
      hideSymbol?: boolean;
      decimals?: number;
    } & Intl.NumberFormatOptions,
  ) => string;
  toAmount: (lamports: Big) => Big;
  toAmountStr: (
    lamports: Big,
    options?: {
      hideComma?: boolean;
      hideSymbol?: boolean;
      decimals?: number;
    } & Intl.NumberFormatOptions,
  ) => string;
  toLamports: (amount: number) => Big;
  formatAmount: (
    amount: number,
    options?: {
      hideComma?: boolean;
      hideSymbol?: boolean;
      decimals?: number;
    } & Intl.NumberFormatOptions,
  ) => string;
  toBalance: (amount: number | Big) => Balance;
  lamportsToBalance: (lamports: Big) => Balance;
};

export const EMPTY_BALANCE: Balance = {
  amount: '0.00',
  amountNum: 0,
  amountBig: Big(0),
  valueUSD: '$0.00',
  lamports: Big(0),
  valueUSDNum: 0,
};

export const EMPTY_ASSET: Asset = {
  name: 'Unknown',
  symbol: 'Unknown',
  decimals: 8,
  logoURI: '',
  id: '',
  priceUSDNum: 0,
  priceUSD: '0',
  price: Big(0),
  toUSDValue: () => 0,
  toUSDValueStr: () => `0`,
  toAmount: () => Big(0),
  toAmountStr: () => `0`,
  toLamports: () => Big(0),
  formatAmount: () => `0`,
  amountToUSD: () => 0,
  amountToUSDStr: () => `0`,
  toBalance: () => ({
    amount: '0',
    amountNum: 0,
    amountBig: Big(0),
    valueUSD: '0',
    lamports: Big(0),
    valueUSDNum: 0,
  }),
  lamportsToBalance: () => ({
    amount: '0',
    amountNum: 0,
    amountBig: Big(0),
    valueUSD: '0',
    lamports: Big(0),
    valueUSDNum: 0,
  }),
};

export { Big } from 'big.js';
